import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import AtyumToken from "../../abis/AtyumToken.json";
import AtyumTokenSale from "../../abis/AtyumTokenSale.json";
import AtyumTokenSaleFactory from "../../abis/AtyumTokenSaleFactory.json";
import { useInvalidNetwork } from "../../hooks/use-invalid-network";
import { useIpfsReadJson } from "../../hooks/use-ipfs-read-json";
import { useIsSaleOpen } from "../../hooks/use-is-sale-open";
import { setIsLoading } from "../../states/loading";
import { setShowLogin, setShowProfile } from "../../states/wallet";
import Description from "../description/description";
import Kyc from "../kyc/kyc";
import CustomProgressBar from "../progress-bar/progress-bar";
import Slider from "../slider/slider";
import SwitchNetworkModal from "../switch-chain/switch-network-modal";
import "./see-sale-details.scss";
import config from "../../config.json";

const SeeSaleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { address, isConnected } = useAccount();

  const [inputAmount, setInputAmount] = useState(0);
  const [contractReaded, setContractReaded] = useState(false);
  const [showPaymentButtons, setShowPaymentButtons] = useState(false);
  const [loadingPSD2, setLoadingPSD2] = useState(false);
  const [eurConversion, setEurConversion] = useState();

  const signature = useSelector((state) => state.wallet.signature);
  const kycStatus = useSelector((state) => state.wallet.kycStatus);
  const userEmail = useSelector((state) => state.wallet.userEmail);

  const ipfsNode = process.env.REACT_APP_IPFS_NODE;

  const openProfile = () => {
    if (!isConnected) {
      dispatch(setShowLogin(true));
      return;
    }
    dispatch(setShowProfile(true));
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_KYC_URL + "/payments/rate")
      .then((res) => res.json())
      .then((data) => {
        setEurConversion(data.rate);
      });
  }, []);

  const handleChange = () => {
    setContractReaded(!contractReaded);
  };

  const handleKeyDown = (event) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
    ];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9\b]+$/;

    if (inputValue === "" || regex.test(inputValue)) {
      setInputAmount(inputValue);
    }
  };

  const { data } = useContractRead({
    address: id,
    abi: AtyumTokenSale,
    functionName: "saleData",
  });

  const { data: isSaleOpen, isLoading: isSaleOpenLoading } = useIsSaleOpen(
    data?.token
  );

  if (!isSaleOpenLoading && !isSaleOpen) {
    navigate({
      pathname: "/",
    });
  }

  const convertToEur =
    config["themes"][config["domains"][window.location.hostname]].eurConversion;
  let tokenPriceInEUR = 0;
  let tokenPriceInUSD = 0;

  if (data && data.price) {
    tokenPriceInUSD = parseFloat(
      ethers.utils.formatUnits(
        data.price
          .mul(1 * 10 ** parseInt(process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS))
          .toString(),
        18
      )
    );

    tokenPriceInEUR = (1 / tokenPriceInUSD) * eurConversion;
    tokenPriceInUSD = 1 / tokenPriceInUSD;
  }

  const downloadContract = () => {
    dispatch(setIsLoading(true));
    fetch(process.env.REACT_APP_KYC_URL + "/kyc/contract/sale/" + id, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        ...signature,
      }),
    })
      .then(async (data) => {
        return await data.text();
      })
      .then((data) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = "contrato.pdf";
        downloadLink.click();
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error al descargarse el contrato. Intentalo más tarde.");
        dispatch(setIsLoading(false));
      });
  };

  const startPSD2Payment = () => {
    setLoadingPSD2(true);
    let redirect = window.location.href;
    if (window.location.hostname === "localhost") {
      redirect = "https://atyum.com";
    }

    fetch(process.env.REACT_APP_KYC_URL + "/payments", {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        ...signature,
        redirect: redirect + "/payment/ok",
        redirectError: redirect + "/payment/ko",
        sale: id,
        amount: parseInt(
          ethers.utils.formatUnits(
            ethers.utils.parseUnits(
              (tokenPriceInUSD * inputAmount).toFixed(
                process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
              ),
              process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
            ),
            4
          )
        ),
      }),
    })
      .then(async (data) => {
        if (data.status !== 201) {
          toast.error("Error al iniciar el pago. Intentalo más tarde.");
          setLoadingPSD2(false);
          return;
        }
        window.location.href = (await data.json()).url;
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error al iniciar el pago. Intentalo más tarde.");
        setLoadingPSD2(false);
      });
  };

  const { data: tokenData } = useContractReads({
    contracts: [
      {
        address: data ? data.token : null,
        abi: AtyumToken,
        functionName: "name",
      },
      {
        address: data ? data.token : null,
        abi: AtyumToken,
        functionName: "category",
      },
    ],
    enabled: data,
  });

  const invalidChain = useInvalidNetwork();

  const { data: allowanceData } = useContractReads({
    contracts: [
      {
        address: process.env.REACT_APP_PAYMENT_TOKEN,
        abi: AtyumToken,
        functionName: "allowance",
        args: [address, process.env.REACT_APP_SALE_FACTORY],
      },
      {
        address: process.env.REACT_APP_PAYMENT_TOKEN,
        abi: AtyumToken,
        functionName: "balanceOf",
        args: [address],
      },
    ],
    cacheTime: 1000,
  });

  const tokenAllowed =
    allowanceData && allowanceData[0]
      ? ethers.utils.formatUnits(
          allowanceData[0].toString(),
          process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
        )
      : 0;

  const tokenBalance =
    allowanceData && allowanceData[1]
      ? ethers.utils.formatUnits(
          allowanceData[1].toString(),
          process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
        )
      : 0;

  const MAX_INT =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

  const { config: approveConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_PAYMENT_TOKEN,
    abi: AtyumToken,
    functionName: "approve",
    args: [process.env.REACT_APP_SALE_FACTORY, MAX_INT],
  });

  const { write: approveWrite, data: approveData } =
    useContractWrite(approveConfig);

  const { isLoading: isApproveLoading } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const { config: buyConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_SALE_FACTORY,
    abi: AtyumTokenSaleFactory,
    functionName: "buy",
    args: [
      id,
      ethers.utils.parseUnits(
        (tokenPriceInUSD * inputAmount).toFixed(
          process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
        ),
        process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
      ),
      address,
    ],
  });

  const { write: buyWrite, data: buyData } = useContractWrite(buyConfig);

  const {
    isLoading: isBuyLoading,
    isSuccess: isBuySuccess,
    isError: isBuyError,
  } = useWaitForTransaction({
    hash: buyData?.hash,
  });

  useEffect(() => {
    if (isBuyLoading) {
      dispatch(setIsLoading(true));
    } else {
      dispatch(setIsLoading(false));
    }
  }, [isBuyLoading]);

  useEffect(() => {
    if (isBuySuccess) {
      navigate("/profile");
      toast.success("Comprado correctamente!");
    }
  }, [isBuySuccess]);

  useEffect(() => {
    if (isBuyError) {
      toast.error("No se ha podido realizar la compra.");
    }
  }, [isBuyError]);

  let progress = 0;

  if (data) {
    progress = Math.ceil(
      (parseFloat(
        ethers.utils.formatUnits(data.totalMintedBySale.toString(), 18)
      ) *
        100) /
        parseFloat(ethers.utils.formatUnits(data.hardCap.toString(), 18))
    ).toFixed(2);
  }

  const { data: info } = useIpfsReadJson(data ? data.saleInfo : null);

  if (showPaymentButtons && invalidChain) {
    return <SwitchNetworkModal></SwitchNetworkModal>;
  }

  return (
    <>
      <div className="container pb-4 pt-4">
        <div className="section_title">
          <div className="row header_container">
            <div className="col-xl-10 col-lg-6 col-md-5 col-12 header_container_item">
              <h4 className="wallet_info_txt">
                <span
                  className="my_sale_details_link_back"
                  onClick={() => navigate(-1)}
                >
                  <img height={11} src="/images/back-arrow-icon.png" alt="" />{" "}
                  Volver
                </span>
              </h4>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-5 col-12 header_container_item end"></div>
          </div>
        </div>
      </div>

      {!data && !tokenData && (
        <div className="container">
          <div className="row pt-2">
            <div className="col-lg-12">
              <strong>Cargando...</strong>
            </div>
          </div>
        </div>
      )}

      {data && tokenData && (
        <div className="container mt-4 mb-5">
          <div className="see_sale_detail_card">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-7 col-12">
                <div className="row">
                  {info && <Slider images={info.images}></Slider>}
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-5 col-12 see_sale_detail_card_container_info">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="see_sale_detail_card_name">
                      {tokenData ? tokenData[0] : "..."}
                    </p>
                  </div>
                </div>

                <div className="row justify-center">
                  <div className="col-6 pt-3 justify-sm-center-and-lg-start block-bigger">
                    <a
                      href={info ? ipfsNode + info.whitepaper : void 0}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="see_sale_detail_card_whitepaper">
                        Ver Whitepaper
                      </span>
                    </a>
                  </div>

                  <div className="col-6 pt-3 justify-sm-center-and-lg-end block-bigger">
                    <Kyc />
                  </div>
                </div>

                <div className="row pt-4 pb-3">
                  {data && data.token && (
                    <a
                      href={
                        process.env.REACT_APP_BLOCKCHAIN_EXPLORER +
                        "address/" +
                        data.token
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="token_address_txt">
                        Token:{" "}
                        {data.token.substring(0, 7) +
                          "…" +
                          data.token.substring(
                            data.token.length,
                            data.token.length - 7
                          )}
                        <img
                          className="pl-3 redirect-logo"
                          src="/images/redirect-icon.png"
                          width={12}
                          alt=""
                        />
                      </span>
                    </a>
                  )}
                </div>

                <Description
                  text={info ? info.description : "..."}
                ></Description>

                <div className="row pt-2">
                  <div className="col-lg-12">
                    <CustomProgressBar progress={progress} />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="see_sale_detail_card_totalraise_txt">
                      Financiación Total
                    </p>
                    <p className="see_sale_detail_card_totalraise">
                      {(
                        parseFloat(ethers.utils.formatUnits(data.hardCap, 18)) *
                        (convertToEur ? tokenPriceInEUR : tokenPriceInUSD)
                      ).toLocaleString("es-ES", {
                        maximumFractionDigits: 0,
                      })}
                      {convertToEur ? "€" : "$"}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="see_sale_detail_card_price_txt">
                      Precio Por 1 Token
                    </p>
                    <p className="see_sale_detail_card_price">
                      {(convertToEur
                        ? tokenPriceInEUR
                        : tokenPriceInUSD
                      ).toLocaleString("es-ES", {
                        maximumFractionDigits: 0,
                      })}
                      {convertToEur ? "€" : "$"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="see_sale_detail_card_date_txt">Fecha Final</p>
                    <p className="see_sale_detail_card_date">
                      {new Date(data.endDate * 1000).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="see_sale_detail_card_apr_txt">
                      Rentabilidad Anualizada
                    </p>
                    <p className="see_sale_detail_card_apr">
                      {info ? info.apr : "..."}%
                    </p>
                  </div>
                </div>
                {kycStatus == "COMPLETED_AND_SYNCED" && (
                  <div className="pt-3 pb-3">
                    <div className="row justify-center">
                      <div className="col-lg-10 col-12 pt-1 pb-3">
                        <span className="invest_txt">Invertir</span>
                      </div>
                    </div>
                    <div className="row justify-center">
                      <div className="col-lg-10 col-12">
                        <div className="input_container">
                          <input
                            className="see_sale_detail_card_amount"
                            type="text"
                            maxLength="11"
                            value={inputAmount}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Tokens"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-center pt-3">
                      <div className="col-10 pb-3 justify-center">
                        <i>
                          Vas a invertir{" "}
                          {(
                            inputAmount *
                            (convertToEur ? tokenPriceInEUR : tokenPriceInUSD)
                          ).toLocaleString("es-ES", {
                            maximumFractionDigits: 2,
                          })}
                          {convertToEur ? "€" : "$"} y obtendrás {inputAmount}{" "}
                          tokens.
                        </i>
                      </div>
                    </div>
                    <div className="row justify-center">
                      <div className="col-lg-10 justify-center">
                        <input
                          type="checkbox"
                          checked={contractReaded}
                          onChange={handleChange}
                          className="checkbox"
                        />
                        <span>
                          He leído y acepto el <br />
                          <a
                            className="contract-link"
                            onClick={() => downloadContract()}
                          >
                            contrato de compraventa
                          </a>
                        </span>
                      </div>
                    </div>
                    {!showPaymentButtons && (
                      <div className="row justify-center pt-4">
                        <div className="col-6 justify-center see_sale_detail_card_buy_container">
                          <button
                            className={"see_sale_detail_card_buy"}
                            onClick={() => setShowPaymentButtons(true)}
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                    )}

                    {showPaymentButtons &&
                      config["themes"][
                        config["domains"][window.location.hostname]
                      ].manualFiatPayment && (
                        <div className="col-12 col-lg-12 justify-center text-center see_sale_detail_card_buy_container pt-3">
                          Transfiere{" "}
                          {(tokenPriceInEUR * inputAmount).toFixed(2)} EUR al
                          IBAN: <br />
                          {
                            config["themes"][
                              config["domains"][window.location.hostname]
                            ].manualFiatPayment
                          }{" "}
                          con el concepto: <br /> Prestamo Participativo{" "}
                          {tokenData ? tokenData[0] : "..."} {userEmail}
                        </div>
                      )}

                    {showPaymentButtons && (
                      <>
                        <div className="row justify-center pt-1">
                          {config["themes"][
                            config["domains"][window.location.hostname]
                          ].psd2Enabled && (
                            <div className="col-12 col-lg-6 justify-center see_sale_detail_card_buy_container pt-3">
                              <button
                                className={
                                  !loadingPSD2 &&
                                  !isBuyLoading &&
                                  contractReaded &&
                                  inputAmount != 0
                                    ? "see_sale_detail_card_buy"
                                    : "see_sale_detail_card_buy_disabled"
                                }
                                onClick={() => startPSD2Payment()}
                                disabled={loadingPSD2}
                              >
                                Transferencia
                              </button>
                            </div>
                          )}

                          {config["themes"][
                            config["domains"][window.location.hostname]
                          ].manualUsdtPayment && (
                            <div className="col-12 col-lg-6 justify-center see_sale_detail_card_buy_container pt-3">
                              Envia {(tokenPriceInUSD * inputAmount).toFixed(2)}{" "}
                              USDT ERC20 al wallet <br />
                              {
                                config["themes"][
                                  config["domains"][window.location.hostname]
                                ].manualUsdtPayment
                              }
                            </div>
                          )}
                          {!config["themes"][
                            config["domains"][window.location.hostname]
                          ].manualUsdtPayment && (
                            <div className="col-12 col-lg-6 justify-center see_sale_detail_card_buy_container pt-3">
                              {tokenAllowed > inputAmount * tokenPriceInUSD &&
                                tokenBalance >=
                                  inputAmount * tokenPriceInUSD && (
                                  <button
                                    className={
                                      !isBuyLoading &&
                                      contractReaded &&
                                      inputAmount * tokenPriceInUSD != 0
                                        ? "see_sale_detail_card_buy_crypto"
                                        : "see_sale_detail_card_buy_disabled"
                                    }
                                    onClick={() =>
                                      buyWrite && contractReaded
                                        ? buyWrite?.()
                                        : () => {}
                                    }
                                    disabled={!buyWrite && !contractReaded}
                                  >
                                    {isBuyLoading
                                      ? "Comprando..."
                                      : "Pagar " +
                                        (tokenPriceInUSD * inputAmount).toFixed(
                                          2
                                        ) +
                                        " USDT"}
                                  </button>
                                )}

                              {tokenAllowed < inputAmount * tokenPriceInUSD &&
                                tokenBalance >=
                                  inputAmount * tokenPriceInUSD && (
                                  <button
                                    className={
                                      !isApproveLoading
                                        ? "see_sale_detail_card_buy_crypto"
                                        : "see_sale_detail_card_buy_disabled"
                                    }
                                    onClick={() => approveWrite?.()}
                                    disabled={!approveWrite}
                                  >
                                    {isApproveLoading
                                      ? "Aprobando..."
                                      : "Aprobar Tokens"}
                                  </button>
                                )}

                              {tokenBalance < inputAmount * tokenPriceInUSD && (
                                <button className="see_sale_detail_card_buy_disabled">
                                  Insuficientes USDT
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}

                {kycStatus == "COMPLETED" && (
                  <div className="pt-3 pb-3 see_sale_detail_card_kyc">
                    <div className="row justify-center text-center">
                      <div className="col-lg-10">
                        <span className="bold">
                          Tu KYC ha sido verificado con exito. Espera unos
                          minutos antes de poder realizar tu prima inversión. El
                          tiempo de espera aproximados son 3 minutos.
                        </span>
                      </div>
                    </div>

                    <div className="row justify-center pt-4">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 see_sale_detail_card_buy_container">
                        <button
                          className="see_sale_detail_card_buy_disabled"
                          disabled={!inputAmount}
                        >
                          Comprar
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {(kycStatus == "PENDING" || kycStatus == "NOTHING") && (
                  <div className="pt-3 pb-3 see_sale_detail_card_kyc">
                    <div className="row justify-center text-center">
                      <div className="col-lg-10">
                        <span className="bold">
                          Realiza el proceso de KYC para proceder con la compra.
                          Es un proceso sencillo que no te llevará más de 3
                          minutos.
                        </span>
                      </div>
                    </div>

                    <div className="row justify-center pt-4">
                      <div className="col-10 justify-center">
                        <Kyc />
                      </div>
                    </div>
                  </div>
                )}

                {kycStatus == "FAILED" && (
                  <div className="pt-3 pb-3 see_sale_detail_card_kyc">
                    <div className="row justify-center text-center">
                      <div className="col-lg-10">
                        <span className="bold">
                          Tu último KYC no se ha podido verificar correctamente,
                          vuelve a intentarlo de nuevo para proceder con la
                          compra.
                        </span>
                      </div>
                    </div>

                    <div className="row justify-center pt-4">
                      <div className="col-6 justify-center">
                        <Kyc />
                      </div>
                    </div>
                  </div>
                )}

                {!address && (
                  <div className="pt-4 pb-4 see_sale_detail_card_kyc">
                    <div className="row justify-center text-center">
                      <div className="col-10 justify-center">
                        <button
                          className="see_sale_detail_card_login"
                          onClick={() => openProfile()}
                        >
                          Inicia sesión para invertir!
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SeeSaleDetails;
