import { useState } from 'react';

const Description = ({ text }) => {
    
    const [showMore, setShowMore] = useState(false);

    let textShort = '';
    if (text) {
        const regex = /(<([^>]+)>)/gi;
        textShort = text.replace(regex, "");
      }
    return (
        <div className="row">
            <div className="col-lg-12">
                {showMore && (
                    <p
                        className="see_sale_detail_card_description"
                        dangerouslySetInnerHTML={{
                            __html: text ? text : "...",
                        }}
                    ></p>
                )}
                {!showMore && (
                    <p className="see_sale_detail_card_description">
                        {text ? textShort.substring(0, 300) : ""}
                        <br />
                        <a
                            href={void 0}
                            className="pointer read_more_txt"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        >
                            Leer más...
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
}

export default Description;